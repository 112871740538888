<template>
  <div>
    <!-- 메뉴 -->
    <nav
      v-show="$store.state.isLogin"
      class="navbar navbar-expand-lg navbar-dark bg-primary"
      id="navsmenu"
    >
      <span
        style="color: white; font-weight: 600; font-size: 20px; width: 40%"
        @click="this.$router.push('./personreport')"
        data-toggle="collapse"
        data-target="#navbarText"
        v-if="$store.state.classid == '4'"
        >청소년 1부 플러스 출석부</span
      >
      <span
        style="color: white; font-weight: 600; font-size: 20px; width: 40%"
        @click="this.$router.push('./personreport')"
        data-toggle="collapse"
        data-target="#navbarText"
        v-else
        >청소년 {{ $store.state.classid }}부 출석부</span
      >
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <button
              v-show="$store.state.isLogin"
              type="button"
              class="btn btn-primary btn-sm"
              style="width: 100px; font-size: 1em; font-weight: 500"
              @click="this.$router.push('./personreport')"
              data-toggle="collapse"
              data-target="#navbarText"
            >
              출석체크
            </button>
          </li>
          <!-- <li class="nav-item">
            <button
              v-show="$store.state.isLogin && rolecheck < 4"
              type="button"
              class="btn btn-primary btn-sm"
              style="width: 100px; font-size: 1em; font-weight: 500"
              @click="this.$router.push('./attendallcount')"
              data-toggle="collapse"
              data-target="#navbarText"
            >
              전체출결
            </button>
          </li>
          <li class="nav-item">
            <button
              v-show="$store.state.isLogin && rolecheck < 4"
              type="button"
              class="btn btn-primary btn-sm"
              style="width: 100px; font-size: 1em; font-weight: 500"
              @click="this.$router.push('./attendstudentlist')"
              data-toggle="collapse"
              data-target="#navbarText"
            >
              반별출결
            </button>
          </li> -->
          <li class="nav-item">
            <button
              v-show="$store.state.isLogin && rolecheck < 4"
              type="button"
              class="btn btn-primary btn-sm"
              style="width: 100px; font-size: 1em; font-weight: 500"
              @click="this.$router.push('./notice')"
              data-toggle="collapse"
              data-target="#navbarText"
            >
              전달사항
            </button>
          </li>
          <!-- <li class="nav-item">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              style="width: 100px; font-size: 1em; font-weight: 500"
              @click="this.$router.push('./takephoto')"
              data-toggle="collapse"
              data-target="#navbarText"
            >
              사진촬영
            </button>
          </li> -->
          <li class="nav-item">
            <button
              v-show="$store.state.isLogin && rolecheck < 4"
              type="button"
              class="btn btn-primary btn-sm"
              style="width: 100px; font-size: 1em; font-weight: 500"
              @click="this.$router.push('./attendance')"
              data-toggle="collapse"
              data-target="#navbarText"
            >
              사진출력
            </button>
          </li>
          <li class="nav-item">
            <button
              v-show="$store.state.isLogin"
              type="button"
              class="btn btn-primary btn-sm"
              style="width: 100px; font-size: 1em; font-weight: 500"
              @click="this.$router.push('./logout')"
              data-toggle="collapse"
              data-target="#navbarText"
            >
              로그아웃
            </button>
          </li>
        </ul>
        <span class="navbar-text"> </span>
      </div>
    </nav>
    <!-- 메뉴 -->

    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
  export default {
    name: "app",
    data() {
      return {
        id: this.$store.state.id,
        grade: this.$store.state.grade,
        phone: this.$store.state.phone,
        name: this.$store.state.name,
        teacherid: this.$store.state.teacherid,
        role: parseInt(this.$store.state.role),
        token: this.$store.state.token,
        classid: this.$store.state.classid
          ? this.$store.state.classid
          : this.$cookies.get("classid"),
        isLogin: false,
      };
    },

    mounted() {
      this.initLogin();
    },
    create() {
      this.initLogin();

      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData) {
        this.id = userData.id;
        this.grade = userData.grade;
        this.phone = userData.phone;
        this.name = userData.name;
        this.teacherid = userData.teacherid;
        this.role = parseInt(userData.role);
        this.token = userData.token;
        this.classid = userData.classid;
      }

      console.log("this.classid", this.classid);
    },
    computed: {
      isAuthenticated() {
        let isLocalAuthenticated = false;
        const userData = localStorage.getItem("userData");
        isLocalAuthenticated = this.$store.state.isAuthenticated;
        if (userData) {
          isLocalAuthenticated = true;
        }
        console.log("isAuthenticated == ", isLocalAuthenticated);
        return isLocalAuthenticated;
      },
      rolecheck() {
        const userData = JSON.parse(localStorage.getItem("userData"));
        let rolenum;
        console.log("userData", userData);
        rolenum = parseInt(userData.role);
        console.log("rolenum", userData.role);
        return rolenum;
      },
    },
    methods: {
      initLogin() {
        // localStorage에서 로그인 상태를 가져옵니다.
        const isLogin = JSON.parse(localStorage.getItem("userData"));
        //console.log("this.isLogin" ,  isLogin.id);
        // localStorage에 로그인 상태가 저장되어 있으면 이를 사용합니다.
        if (isLogin !== null && isLogin.id) {
          this.$store.state.isLogin = true;
          this.$store.state.id = isLogin.id;
          this.$store.state.grade = isLogin.grade;
          this.$store.state.phone = isLogin.phone;
          this.$store.state.name = isLogin.name;
          this.$store.state.teacherid = isLogin.teacherid;
          this.$store.state.role = isLogin.role;
          this.$store.state.token = isLogin.token;
          this.$store.state.classid = isLogin.classid;
        } else {
          this.$store.state.isLogin = false;
          this.$store.state.name = "";
          this.$store.state.role = "";
          this.$store.state.grade = "";
        }
      },
      logout() {
        localStorage.removeItem("id");
        localStorage.removeItem("grade");
        localStorage.removeItem("phone");
        localStorage.removeItem("name");
        localStorage.removeItem("teacherid");
        localStorage.removeItem("role");
        localStorage.removeItem("classid");
        localStorage.removeItem("isLogin");
        localStorage.removeItem("userData");
        localStorage.clear();

        this.$store.dispatch("logout");
        this.$cookies.remove("phone");
        this.$cookies.remove("classid");
        this.$store.commit("logout");
        this.$store.commit("setLogoutState");

        localStorage.setItem("isLogin", "false");
        this.$store.state.isLogin = false;

        this.$router.push({ path: "/login" });
      },
    },
  };
</script>
<style>
  body {
    font-family: "Noto Sans KR", "Helvetica Neue", sans-serif;
  }
  a {
    color: #369;
    text-decoration: none;
  }
</style>

<!-- 
          <li class="nav-item">
            <button
              v-show="userInfo"
              type="button"
              class="btn btn-primary btn-sm"
              style="
                width: 200px;
                font-size: 1em;
                font-weight: 500;
                text-align: left;
              "
              @click="this.$router.push('./exceldownload_dept')"
            >
            자료 다운로드
            </button>
          </li>
          <li class="nav-item">
            <button
              v-show="userInfo && grade > 1"
              type="button"
              class="btn btn-primary btn-sm"
              style="
                width: 200px;
                font-size: 1em;
                font-weight: 500;
                text-align: left;
              "
              @click="this.$router.push('./exceldownload')"
            >
              Excel Download(PMO)
            </button>
          </li>
          <li class="nav-item">
            <button
              v-show="userInfo && grade == 3"
              type="button"
              class="btn btn-primary btn-sm"
              style="
                width: 200px;
                font-size: 1em;
                font-weight: 500;
                text-align: left;
              "
              @click="this.$router.push('./sample')"
            >
              Sample
            </button>
          </li>
          <li class="nav-item">
            <button
              v-show="userInfo"
              type="button"
              class="btn btn-primary btn-sm"
              style="
                width: 200px;
                font-size: 1em;
                font-weight: 500;
                text-align: left;
              "
              @click="this.$router.push('./chart')"
            >
              통계
            </button>
          </li>
          <li class="nav-item">
            <button
              v-show="isLogin"
              type="button"
              class="btn btn-primary btn-sm"
              style="width: 80px; font-size: 1em; font-weight: 500"
              @click="this.$store.dispatch('logout')"
            >
              Logout
            </button>
          </li>-->
